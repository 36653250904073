import request from '@/utils/request'


// 查询用户账单明细列表
export function listAccountBill(query) {
  return request({
    url: '/accountBill/accountBills/list',
    method: 'get',
    params: query
  })
}

// 查询用户账单明细分页
export function pageAccountBill(query) {
  return request({
    url: '/accountBill/accountBills/page',
    method: 'get',
    params: query
  })
}

// 查询用户账单明细详细
export function getAccountBill(data) {
  return request({
    url: '/accountBill/accountBills/detail',
    method: 'get',
    params: data
  })
}

// 新增用户账单明细
export function addAccountBill(data) {
  return request({
    url: '/accountBill/accountBills/add',
    method: 'post',
    data: data
  })
}

// 修改用户账单明细
export function updateAccountBill(data) {
  return request({
    url: '/accountBill/accountBills/edit',
    method: 'post',
    data: data
  })
}

// 删除用户账单明细
export function delAccountBill(data) {
  return request({
    url: '/accountBill/accountBills/delete',
    method: 'post',
    data: data
  })
}
