<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
              <a-form-item label="用户showId" prop="showId">
                <a-input v-model="queryParam.showId" placeholder="请输入用户showId" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="5" :sm="24">
              <a-form-item label="用户昵称" prop="nickname">
                <a-input v-model="queryParam.nickname" placeholder="请输入用户昵称" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="5" :sm="24">
                <a-form-item label="类型" prop="billType">
                  <a-select v-model="queryParam.billType"   placeholder="请选择类型" >
                    <a-select-option :value="0">会员每日赠送</a-select-option>
                    <a-select-option :value="1">充值分销收入</a-select-option>
                    <a-select-option :value="2">开通会员赠送</a-select-option>
                    <a-select-option :value="3">签到</a-select-option>
                    <a-select-option :value="4">购买商品赠送钻石</a-select-option>
<!--                    <a-select-option :value="5">观看5分钟奖励</a-select-option>-->
                    <a-select-option :value="6">观看视频奖励</a-select-option>
                    <a-select-option :value="7">购买剧集</a-select-option>
                    <a-select-option :value="8">邀请用户</a-select-option>
                    <a-select-option :value="9">钻石充值</a-select-option>
                    <a-select-option :value="10">首次分享</a-select-option>
                    <a-select-option :value="11">首次开启推送</a-select-option>
                    <a-select-option :value="12">首次开启推送</a-select-option>
                    <a-select-option :value="13">退款</a-select-option>
                    <a-select-option :value="14">购买会员分销收入</a-select-option>
                    <a-select-option :value="15">购买商品分销收入</a-select-option>

                  </a-select>

                </a-form-item>
              </a-col>

              <a-col :md="8" :sm="24">
                <a-form-item label="查询时间" prop="createTime">
                  <a-date-picker style="width: 100%" v-model="queryParam.createTime" format="YYYY-MM-DD" allow-clear/>
                </a-form-item>
              </a-col>

            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['accountBill:accountBill:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['accountBill:accountBill:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['accountBill:accountBill:remove']">-->
<!--          <a-icon type="delete" />删除-->
<!--        </a-button>-->
        <a-button type="primary" @click="handleExport" v-hasPermi="['accountBill:accountBill:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['accountBill:accountBill:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['accountBill:accountBill:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['accountBill:accountBill:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['accountBill:accountBill:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageAccountBill,listAccountBill, delAccountBill } from '@/api/accountBill/accountBill'
import CreateForm from './modules/CreateForm'
import moment from "moment";
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'AccountBill',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        userId: null,
        type: null,
        changeType: null,
        title: null,
        beforeMoney: null,
        changeMoney: null,
        createTime: null,
        afterMoney: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '用户showId',
          dataIndex: 'showId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '类型',
        //   dataIndex: 'type',
        //   ellipsis: true,
        //   customRender: function (t, r, index) {
        //     if(t == 0) {
        //       return '金币'
        //     }else if(t == 1) {
        //       return '钻石'
        //     }
        //   },
        //   align: 'center'
        // },
        {
          title: '变动类型',
          dataIndex: 'changeType',
          ellipsis: true,
            customRender: function (t, r, index) {
              if(t == 0) {
                return '收入'
              }else if(t == 1) {
                return '支出'
              }
            },
          align: 'center'
        },
        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '变动前金额',
          dataIndex: 'beforeMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '变动金额',
          dataIndex: 'changeMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '变动后金额',
          dataIndex: 'afterMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   width: '18%',
        //   scopedSlots: { customRender: 'operation' },
        //   align: 'center'
        // }
      ]
    }
  },
  filters: {
  },
  created () {
    console.log(11111111111111)
    console.log(this.$router)
    this.queryParam.showId=this.$route.query.showId
    this.getList()
  },

  computed: {

  },
  watch: {
    '$route'() {
      this.queryParam.showId=this.$route.query.showId
      this.getList()
      // this.getListBusinessType();
    }
  },
  mounted() {
    this.queryParam.showId=this.$route.query.showId
    this.getList()
  },
  methods: {
    /** 查询用户账单明细列表 */
    getList () {
      this.loading = true

      //
      if (moment.isMoment(this.queryParam.createTime)) {
        this.queryParam.createTime = this.queryParam.createTime.format('YYYY-MM-DD')
      }
     pageAccountBill(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        type: undefined,
        changeType: undefined,
        title: undefined,
        beforeMoney: undefined,
        changeMoney: undefined,
        afterMoney: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },



    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delAccountBill(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('accountBill/accountBills/export', {
            ...that.queryParam
          }, `用户账单明细_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
